import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Box, Text } from "../../components/Core";
import { device } from "../../utils";
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import FullScreenLoader from "../../components/FullScreenLoader";
import AdditionalSupportInformation from "../../sections/support/additionalSupportInformation";
import checkMarkIcon from "../../assets/image/svg/success-check.svg";
import { Link } from "gatsby";
import { Button, Title } from "../../components/Core";
import Seo from "../../components/Seo/Seo";

const BigTitle = styled(Title)`
  font-size: 2.8em;
`

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  margin-top: -65px;
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`;

const renderTitle = (page) => {
    switch (page) {
        case 'voucher':
            return 'Your request is sent!';
        case 'timetoswitch':
            return 'We’ve got your details';
        default:
            return 'Your request is sent!';
    }
}

const renderText = (page) => {
    switch (page) {
        case 'voucher':
            return <>
                <p>Thank you for registering your interest with YouFibre.</p>
                <p className="p-0 m-0">
                    In coming weeks, you’ll receive an email from DCMS to ask if you
                    want full fibre broadband through the Gigabit Broadband Voucher
                    Scheme. Just tell DCMS you are interested, then sit back and we’ll
                    start the
                    process of bringing full fibre broadband to you!
                </p>
            </>;
        case 'timetoswitch':
            return <>
                <p>Thank you for registering your interest with YouFibre.</p>
                <p className="p-0 m-0">
                    We will be in touch soon to tell you more about our current deals and how to get connected to our ultrafast broadband.
                </p>
            </>;
        default:
            return <>
                <p>Thank you for registering your interest with YouFibre.</p>
                <p className="p-0 m-0">
                    In coming weeks, you’ll receive an email from DCMS to ask if you
                    want full fibre broadband through the Gigabit Broadband Voucher
                    Scheme. Just tell DCMS you are interested, then sit back and we’ll
                    start the
                    process of bringing full fibre broadband to you!
                </p>
            </>;
    }
}

const RequestSent = () => {
    const gContext = useContext(GlobalContext);
    const pageParent = gContext.goGetRegistrationData() && gContext.goGetRegistrationData().parent;

    useEffect(() => {
        if (gContext.fullScreenLoader) gContext.hideFullScreenLoader()
    }, [])

    return (
        <div>
            <Seo page="request-sent" />
            <FullScreenLoader title="Processing data ..." />
            <BoxStyled bg="#f7f8fa">
                <BoxInner className="d-flex align-items-center">
                    <Container>
                        <Box my="220px" mx="auto">
                            <FormStyled>
                                <Row className="justify-content-center">
                                    <Col sm={12} lg={11} className="text-center mb-4">
                                        <img src={checkMarkIcon} alt="Appointment OK" style={{ maxWidth: '80px' }} />
                                    </Col>

                                    <Col sm={12}>
                                        <BigTitle className="mt-3 mb-4 text-center">
                                            {renderTitle(pageParent)}
                                        </BigTitle>
                                    </Col>

                                    <Col sm={12} lg={11} className="mb-5">
                                        <Box
                                            className="p-4 text-center"
                                            css={`background: linear-gradient(24deg, #e6fcff 0%, #f9ecff 63%)`}
                                            borderRadius="10px"
                                        >
                                            <Text variant="card" className="text-center">
                                                {renderText(pageParent)}
                                            </Text>
                                        </Box>
                                    </Col>
                                    <Col sm={12} lg={4} className="mx-auto">
                                        <Link to={"/"}>
                                            <Button width="100%" borderRadius={10} className="mx-auto">
                                                Return to Homepage
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </FormStyled>
                        </Box>
                        <Box my="120px" mx="auto">
                            <AdditionalSupportInformation
                                title="Something's not quite right?"
                                subtitle="Contact our team and we will help you sort it out in no time."
                            />
                        </Box>
                    </Container>
                </BoxInner>
            </BoxStyled>
        </div>
    )
}

export default RequestSent